:root {
  /* --color-orangeFL: #fdb853;
 
    --color-light-orangeFL: #f6f4eb;
    --color-borderFL: #d9d5c4;
    --color-blackFL: #121212; */


  --grey-rg: #d7d7d7;
  --grey-rg1: #aaadb0;
  --grey-rg2: #c4c4c4;
  --grey-rg3: #ccc;
  /* --black-rg: #252525; */
  --black-rg: #131417;

  --black-rg2: #3B3C41;
  /* --whitish-rg: #dadada; oryginał z reform */
  /* --whitish-rg: whitesmoke; */
  --whitish-rg: #fafafa;


  --pure-white-rg: white;
  --off-white-rg: rgba(255, 255, 255, .4);

  --blue-rg: #0d65ec;
  --blue-light-rg: #a7c1da;

  --green-rg: #1ba372;
  --green-stroke-rg: rgb(104, 162, 141);
  --dark-black-rg: #141414;
  --color-stroke-rg: rgb(157, 157, 157);
  --light-violet: #c5c4d3;
  --lime-rg: #cddcc3;
  --lila-rg: #83668C;
  --lila-dark-rg: #596385;



  /* --brand--bg: whitesmoke; */
  --brand-bg: #fafafa;
  --black: #131417;
  --brand-primary: #142218;
  --grey-80: #4d4e50;
  --white: white;
  --grey-15: #ecedf1;
  --white-50: rgba(255, 255, 255, .5);
  --grey-8-2: #f8f9fa;
  --grey-60: #828283;
  --grey-40: #9fa0a3;
  --grey-20: #cfd1d7;
  --white-25: rgba(255, 255, 255, .25);
  --white-75: rgba(255, 255, 255, .75);
  --brand-secondary: #c0e3d1;
  --brand--tertiary: #93887c;
  --brand--hero-inner-page: #f9f3ee;



  --white-lobo: #f8f5ef;
  --warning: #ff0000;
  --gray: #979797;
  --letter01: #6b64ee;
  --letter02: #11cee9;
  --letter03: #ab6bdb;
  --letter04: #ffaaaa;
  --letter05: #90d63a;
  --letter06: #ff9f48;
  --letter07: #6c69eb;
  --letter08: #56549c;
  --letter09: #e369eb;
}






.container-FL {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box
}

@media(max-width: 1366px) {
  .container-FL {
    max-width: 1140px
  }
}

@media(max-width: 1199px) {
  .container-FL {
    max-width: 908px
  }
}

@media(max-width: 910px) {
  .container-FL {
    max-width: 676px
  }
}

@media(max-width: 767px) {
  .container-FL {
    max-width: 492px
  }
}

@media(max-width: 480px) {
  .container-FL {
    max-width: 343px
  }
}

.s-container-FL {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 30px
}

@media(max-width: 1366px) {
  .s-container-FL {
    max-width: 1140px;
    padding: 0
  }
}

@media(max-width: 1199px) {
  .s-container-FL {
    max-width: 908px
  }
}

@media(max-width: 910px) {
  .s-containerv {
    max-width: 676px
  }
}

@media(max-width: 767px) {
  .s-container-FL {
    max-width: 492px
  }
}

@media(max-width: 480px) {
  .s-container-FL {
    max-width: 343px
  }
}

.m-container-FL {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px
}

@media(max-width: 1366px) {
  .m-container-FL {
    padding: 0 30px
  }
}

@media(max-width: 1199px) {
  .m-container-FL {
    padding: 0 42px
  }
}

@media(max-width: 991px) {
  .m-container-FL {
    padding: 0 46px
  }
}

@media(max-width: 767px) {
  .m-container-FL {
    padding: 0 42px
  }
}

@media(max-width: 575px) {
  .m-container-FL {
    padding: 0 16px
  }
}

.custom-popup .vm--modal {
  background: #fff;
  border-radius: 46px;
  overflow-y: auto
}

html {
  -webkit-text-size-adjust: 100%;
}


body {
  font-family: "Manrope", sans-serif;
  color: var(--black-rg);

  background-color: var(--whitish-rg);
  /* background-color: var(--brand--bg); */
  /* color: var(--black);   */
  /* color: #212121; */
  /* font-size: 1vw; */
  font-weight: 400;
  line-height: 1.5;
  padding-top: 5rem;
  overflow-x: hidden;
}

* {
  scroll-behavior: smooth
}

*,
p {
  margin: 0;
  padding: 0
}

a {
  color: inherit;
  text-decoration: none
}


img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
}





.header.fixed {
  border-color: var(--black-rg2)
}


@media(max-width: 767px) {
  .header.fixed .header__language {
    padding: 7px 11px
  }

}




.home {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
}




.home::-webkit-scrollbar {
  display: none
}

.home {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto
}

.bg-dark {
  background-color: var(--black-rg);
  color: var(--whitish-rg);
}

.bg-darker {
  background-color: #121212;
  color: var(--whitish-rg);
}



.bg-whit {
  background-color: var(--whitish-rg);
  color: var(--black-rg);
}

.areas-block {
  margin-top: -40px
}

.container-left,
.container-right {
  border-top: 1px solid #121212
}

.container-right {
  border-bottom: 1px solid #121212
}


.body__button-info p {

  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: .02em;
  text-transform: uppercase;
  color: #121212
}



@media(max-width: 480px) {
  .body__info-mobile__image {
    /* display:none */
  }
}


@media(max-width: 850px) {
  .body__info {
    /* display:none */
  }
}

.prose__description {
  display: flex;
  align-items: flex-end
}

@media(max-width: 600px) {
  .prose__description {
    flex-direction: column;
    grid-row-gap: 14px;
    row-gap: 14px
  }
}



@media (max-width: 767px) {
  .body__titleDI {
    
    line-height: 1;
    font-size: 3rem !important;
    font-weight: 600;
  }
}
@media (max-width: 1118px) {
  .mobiletext {
    text-align: center;
  
  }
}

/* 
.body__titleDI  { 
    background-image: url(./image3/Dynamic_Duality_max.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    
    background-size: cover;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  
}
  */





.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}


.body__link {
  width: 100%;
  cursor: pointer
}


.prose {
  /* border-radius: 42px 42px 0 0; */
  width: 100%;
  /* padding: 64px 0 128px;
    margin-bottom: -64px */
}

@media(max-width: 1366px) {
  .prose {
    padding: 48px 0 92px;
    /* margin-bottom: -44px */
  }
}

@media(max-width: 910px) {
  .prose {
    padding: 34px 0 112px;
    /* margin-bottom: -52px */
  }
}

@media(max-width: 767px) {
  .prose {
    /* margin-bottom:-83px; */
    padding: 34px 0 140px
  }
}

@media(max-width: 480px) {
  .prose {
    padding: 16px 0 112px;
    /* margin-bottom: -30px; */
    /* border-radius: 32px 32px 0 0 */
  }
}

.prose__heading {
  /* border: 1px ;  */
  border-radius: 62px;
  padding: 5px 12px 3px;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: .02em;
  text-transform: uppercase;
  /* color: var(--whitish-rg); */
}

.prose__heading.white {
  border: 1px solid var(--white);
  color: var(--whitish-rg);
}

.prose__heading.dark {
  border: 1px solid #131417;
  ;
  color: var(--black-rg);
}

@media(max-width: 1366px) {
  .prose__heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: .04em
  }
}

@media(max-width: 910px) {
  .prose__heading {
    font-weight: 430;
    font-size: 14px
  }
}

@media(max-width: 767px) {
  .prose__heading {
    margin-bottom: 38px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .02em
  }
}

@media(max-width: 480px) {
  .prose__heading {
    margin-bottom: 48px
  }
}

.prose__info {
  display: flex;
  flex-direction: column;
  grid-row-gap: 14px;
  row-gap: 14px
}

@media(max-width: 767px) {
  .prose__info {
    grid-row-gap: 10px;
    row-gap: 10px
  }
}

.prose__title {
  font-weight: 600;
  font-size: 56px;
  line-height: 100%;
  text-transform: uppercase;
  max-width: 470px;

  letter-spacing: -1px
}

@media(max-width: 1366px) {
  .prose__title {
    line-height: 96%;
    letter-spacing: -.02em
  }
}

@media(max-width: 1100px) {
  .prose__title {
    font-size: 52px;
    max-width: 300px
  }
}

@media(max-width: 910px) {
  .prose__title {
    font-size: 36px
  }
}

@media(max-width: 767px) {
  .prose__title {
    max-width: 100%
  }
}

@media(max-width: 575px) {
  .prose__title {
    max-width: 341px;
    font-size: 36px;
    line-height: 100%;
    letter-spacing: 0
  }
}

.prose__description {
  font-size: 18px;
  line-height: 150%;

  max-width: 407px;
  font-weight: 500
}

@media(max-width: 1366px) {
  .prose__description {
    font-size: 15px;
    line-height: 164%;
    letter-spacing: .02em
  }
}

@media(max-width: 1100px) {
  .prose__description {
    max-width: 367px
  }
}

@media(max-width: 910px) {
  .prose__description {
    font-size: 14px;
    max-width: 280px
  }
}

@media(max-width: 767px) {
  .prose__description {
    max-width: 100%
  }
}

@media(max-width: 575px) {
  .prose__description {
    max-width: 341px;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0
  }
}

.prose__body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  grid-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px
}

@media(max-width: 767px) {
  .prose__body {
    flex-direction: column-reverse;
    align-items: center;
    height: 100%
  }
}

.body-card__image {
  position: absolute;
  top: 30%;
  left: -109px
}

@media(max-width: 1200px) {
  .body-card__image {
    width: 270px;
    height: 175px
  }
}

@media(max-width: 1100px) {
  .body-card__image {
    height: 137px;
    width: 212px
  }
}

@media(max-width: 767px) {
  .body-card__image {
    height: 117px;
    width: 180px;
    left: -40px
  }
}

@media(max-width: 480px) {
  .body-card__image {
    width: 346px;
    height: 160px;
    left: 10px
  }
}



.body__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%
}

@media(max-width: 767px) {
  .body__left {
    padding-bottom: 34px;
    padding-top: 34px
  }
}

@media(max-width: 480px) {
  .body__left {
    padding-bottom: 24px;
    padding-top: 34px
  }
}

.body__right {
  position: relative;
  height: 100%
}

@media(max-width: 480px) {
  .body__right {
    width: 100%
  }
}

.body__right-image {
  /* width: 630px;
    height: 560px; */

  width: 560px;
  height: 630px;


  /* border-radius: 32px; */
  -o-object-fit: cover;
  object-fit: cover
}

@media(max-width: 1200px) {
  .body__right-image {
    /* width:560px;
        height: 509px; */

    height: 560px;
    width: 509px
  }





}

.obj_pos_c_30 {
  object-position: center 30%;
}

.obj_pos_c_20 {
  object-position: center 20%;
}

.obj_pos_c_10 {
  object-position: center 10%;
}

.obj_pos_center {
  object-position: center;
}



@media(max-width: 480px) {
  .body__right-image {
    width: 100%;
    height: 450px;
    -o-object-fit: cover;
    object-fit: cover
  }
}

@media(max-width: 480px) {
  .desc {
    display: none
  }
}

.mobile {
  display: none
}

@media(max-width: 480px) {
  .mobile {
    display: block
  }
}

.ParallaxContainer {
  overflow: hidden
}

.areas {
  /* background: var(--background); */
  /* border-radius: 42px; */
  padding: 64px 0 208px;
  /* margin-bottom: -80px;
    margin-top: -40px */
}

.areas.aboutme {
  /* background: var(--background); */
  /* border-radius: 42px; */
  padding: 6rem 0 8rem 0;
  /* margin-bottom: -80px;
  margin-top: -40px */
}


@media(max-width: 1366px) {
  .areas {
    padding: 61px 0 228px
  }
}

@media(max-width: 760px) {
  .areas {
    padding: 42px 0 180px
  }
}

@media(max-width: 480px) {
  .areas {
    padding: 32px 0 144px;
    /* border-radius: 32px 32px 0 0; */
    margin-top: -56px
  }
}

.areas__title {
  font-weight: 600;
  font-size: 74px;
  line-height: 100%;

  letter-spacing: -.6px
}

@media(max-width: 1366px) {
  .areas__title {
    font-size: 72px;
    line-height: 114%
  }
}

@media(max-width: 1199px) {
  .areas__title {
    font-size: 62px
  }
}

@media(max-width: 990px) {
  .areas__title {
    font-size: 46px
  }
}

@media(max-width: 760px) {
  .areas__title {
    font-size: 32px
  }
}

@media(max-width: 480px) {
  .areas__title {
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    letter-spacing: -.02em
  }
}

/* 
.areas__info  {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    grid-row-gap: 66px;
    row-gap: 66px;
    padding-bottom: 64px
}

@media(max-width: 1366px) {
    .areas__info  {
        grid-row-gap:30px;
        row-gap: 30px;
        padding-bottom: 62px
    }
}

@media(max-width: 1199px) {
    .areas__info  {
        padding-bottom:110px
    }
}

@media(max-width: 990px) {
    .areas__info  {
        padding-bottom:100px
    }
}

@media(max-width: 760px) {
    .areas__info  {
        padding-bottom:71px;
        grid-row-gap: 24px;
        row-gap: 24px
    }
}

@media(max-width: 480px) {
    .areas__info  {
        grid-row-gap:32px;
        row-gap: 32px;
        padding-bottom: 64px
    }
} */
/* 
.areas__button  {
    background: #fdb853;
    border: 1px solid #121212;
    border-radius: 24px;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: .02em;
    text-transform: uppercase;
    color: #121212;
    display: flex;
    align-items: center;
    grid-column-gap: 6px;
    -moz-column-gap: 6px;
    column-gap: 6px;
    padding: 13px 23px 11px;
    box-sizing: border-box
}

@media(max-width: 850px) {
    .areas__button  {
        width:100%;
        justify-content: center
    }
}

.areas__button img  {
    width: 24px
} */

.areas__headline {
  font-weight: 600;
  font-size: 74px;
  line-height: 90%;
  letter-spacing: -.03em;

  padding-bottom: 112px
}

@media(max-width: 1366px) {
  .areas__headline {
    font-size: 130px;
    line-height: 86%;
    padding-bottom: 140px
  }
}

@media(max-width: 1199px) {
  .areas__headline {
    font-size: 110px;
    line-height: 86%;
    padding-bottom: 68px
  }
}

@media(max-width: 990px) {
  .areas__headline {
    font-size: 80px;
    line-height: 86%;
    padding-bottom: 94px
  }
}

@media(max-width: 760px) {
  .areas__headline {
    font-size: 54px;
    line-height: 86%;
    padding-bottom: 58px;
    text-align: center
  }
}

@media(max-width: 480px) {
  .areas__headline {
    font-size: 48px;
    line-height: 48px;
    padding-bottom: 64px
  }
}

/* .areas__card-info  {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px
}

@media(max-width: 990px) {
    .areas__card-info  {
        grid-template-columns:1fr
    }
 
  
}

@media(max-width: 480px) {
    .areas__card-info  {
        grid-row-gap:16px;
        row-gap: 16px
    }
}

.areas__card-info .card__color:last-child {
    align-items: flex-start
} */

/* .ParallaxElement.App__example-element.phone {
    width: 375px;
    position: absolute;
    left: 50%;
    top: 81%;
    bottom: 0;
    transform: translateX(-50%)
} */

.area-content {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 88px;
  width: 100%;
  grid-column-gap: 129px;
  -moz-column-gap: 129px;
  column-gap: 129px
}

.aboutme .area-content {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 0px;
  width: 100%;
  grid-column-gap: 129px;
  -moz-column-gap: 129px;
  column-gap: 129px
}




@media(max-width: 1366px) {
  .area-content {
    grid-column-gap: 115px;
    -moz-column-gap: 115px;
    column-gap: 115px;
    padding-bottom: 95px
  }
}

@media(max-width: 1199px) {
  .area-content {
    grid-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    padding-bottom: 60px
  }
}

@media(max-width: 767px) {
  .area-content {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-bottom: 50px
  }

  .aboutme .area-content {
    flex-direction: column;
  }
}

@media(max-width: 480px) {
  .area-content {
    padding-bottom: 32px
  }
}

.area-content__info {
  display: flex;
  flex-direction: column;
  grid-row-gap: 16px;
  row-gap: 16px
}

@media(max-width: 767px) {
  .area-content__info {
    padding-top: 24px;
    grid-row-gap: 14px;
    row-gap: 14px;
    display: block;
    margin: auto;
  }
}

@media(max-width: 480px) {
  .area-content__info {
    grid-row-gap: 8px;
    row-gap: 8px
  }
}

.area-content__title {
  font-weight: 600;
  font-size: 74px;
  line-height: 100%;
  letter-spacing: -.01em;
  text-transform: uppercase;
  color: #fff;
  max-width: 400px
}

@media(max-width: 1366px) {
  .area-content__title {
    font-size: 60px;
    letter-spacing: -.02em
  }
}

@media(max-width: 1199px) {
  .area-content__title {
    font-size: 52px;
    max-width: 350px
  }
}

@media(max-width: 990px) {
  .area-content__title {
    font-weight: 430
  }
}

@media(max-width: 767px) {
  .area-content__title {
    font-size: 36px;
    max-width: 400px
  }
}

@media(max-width: 480px) {
  .area-content__title {
    letter-spacing: -.01em;
    line-height: 100%
  }
}

.area-content__description {
  font-style: normal;
  font-size: 18px;
  line-height: 150%;
  color: #fff;
  max-width: 413px;
  font-weight: 500
}

@media(max-width: 1366px) {
  .area-content__description {
    font-weight: 430;
    font-size: 15px;
    line-height: 180%
  }
}

/* @media(max-width: 990px) {
    .area-content__description {
        font-size:14px
    }
} */

@media(max-width: 480px) {
  .area-content__description {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 150%;
    max-width: 343px
  }
}

.area-content__image {
  position: relative;
  width: 738px;
  height: 460px;
  background-size: cover;
  background-position: 50%;
  /* border-radius: 25px; */
  overflow: hidden
}

@media(max-width: 1366px) {
  .area-content__image {
    width: 657px;
    height: 554px
  }
}

@media(max-width: 1199px) {
  .area-content__image {
    width: 444px;
    height: 406px
  }
}

@media(max-width: 990px) {
  .area-content__image {
    min-width: 328px;
    height: 378px
  }
}

@media(max-width: 767px) {
  .area-content__image {
    width: 100%;
    height: 380px
  }
}

@media(max-width: 767px) {
  .area-content__image {
    margin-top: 2rem;
  }
}

.area-content__image img {
  width: 375px;
  position: absolute;
  top: 81%;
  bottom: 0;
  transform: translateX(-50%)
}

@media(max-width: 1199px) {
  .area-content__image img {
    width: 268px;
    top: 73px
  }
}

@media(max-width: 991px) {
  .area-content__image img {
    top: 48px
  }
}

@media(max-width: 480px) {
  .area-content__image img {
    width: 268px
  }
}

@media(max-width: 480px) {
  .area-content__image img.desc {
    display: none
  }
}

@media(min-width: 479px) {
  .area-content__image img.mob {
    display: none
  }
}

@media(max-width: 480px) {
  .area-content__image img.mob {
    top: 38px
  }
}

.area-content.revers {
  flex-direction: row-reverse
}

@media(max-width: 750px) {
  .area-content.revers {
    flex-direction: column-reverse
  }
}

.area-content.light {
  grid-column-gap: 129px;
  -moz-column-gap: 129px;
  column-gap: 129px
}

@media(max-width: 1366px) {
  .area-content.light {
    grid-column-gap: 129px;
    -moz-column-gap: 129px;
    column-gap: 129px;
    grid-column-gap: 98px;
    -moz-column-gap: 98px;
    column-gap: 98px
  }
}

@media(max-width: 990px) {
  .area-content.light {
    grid-column-gap: 19px;
    -moz-column-gap: 19px;
    column-gap: 19px
  }
}

.area-content.light .area-content__title {
  font-size: 56px;

}

@media(max-width: 1366px) {
  .area-content.light .area-content__title {
    font-size: 60px;
    line-height: 94%
  }
}

@media(max-width: 1199px) {
  .area-content.light .area-content__title {
    font-size: 52px
  }
}

@media(max-width: 767px) {
  .area-content.light .area-content__title {
    font-size: 36px
  }
}

.area-content.light .area-content__description {
  font-size: 18px;

}

@media(max-width: 1366px) {
  .area-content.light .area-content__description {
    font-size: 16px;
    line-height: 180%
  }
}

@media(max-width: 1199px) {
  .area-content.light .area-content__description {
    font-size: 16px
  }
}

/* @media(max-width: 767px) {
    .area-content.light .area-content__description {
        font-size:14px
    }
} */

.c3_card {

  border-radius: 32px;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 512px;
  max-width: 413px
}


@media(max-width: 480px) {
  .desc {
    display: none
  }
}

.mob {
  display: none
}

@media(max-width: 480px) {
  .mob {
    display: block
  }
}


.card {
  display: flex;
  grid-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  border: 1px solid #121212;
  /* border-radius: 32px; */
  padding: 32px 61px 32px 30px;
  box-sizing: border-box;
  position: relative
}

.gallery .card {
  display: flex;
  column-gap: 0px;
  padding: 0;
  border: none;
  /* border-radius: 32px; */

  box-sizing: border-box;
  position: relative
}

.galleryimg img {

  /* border-radius: 32px; */

}






.input__parent {
  width: 100%;
}



/*  */
.flex-horizontal {
  align-self: stretch;
  display: flex;
}

.w-container:after {
  clear: both;
}

.w-container:before,
.w-container:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.buttonK {
  /* border: 1px solid var(--whitish-rg); */
  background-color: var(--black-rg);
  color: var(--whitish-rg);
  letter-spacing: -.01em;
  text-transform: none;
  white-space: nowrap;
  border-radius: 50em;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 1;
  display: flex;
  position: relative;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}



.span-text-highlight {
  border: 1px solid #000;
  border-radius: 50em;
  padding: .1em .4em;
  display: inline-block
}



.margin20 {
  margin: 20px;
}

.mtop {
  margin-top: 2rem;
}

/*  */
@media screen and (max-width: 767px) {
  .submit-button {
    width: 100%;
  }

  .link-floating-text {
    text-shadow: 0 15px #000;
  }


}

.submit-button {
  z-index: 1;
  color: var(--dark-black-rg);
  text-align: center;
  letter-spacing: .05em;
  text-transform: uppercase;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  /* background-color: #3898ec; */
  border: 0;
  border-radius: 0;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

.color-white {
  color: var(--whitish-rg)
}

.flex-horizontal {
  align-self: stretch;
  display: flex;
}

.buttonS.submit {
  font-weight: 400;
  position: relative;
}

.buttonS {
  border: 1px solid var(--whitish-rg);
  background-color: var(--dark-black-rg);
  color: var(--whitish-rg);
  letter-spacing: -.01em;
  text-transform: none;
  white-space: nowrap;
  border-radius: 50em;
  justify-content: space-between;
  align-items: center;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1;
  display: flex;
  position: relative;
}

input.w-button {
  -webkit-appearance: button;
}

.button.stroke.white {
  border-color: var(--white);
  color: var(--white);
}

.button.stroke {
  border-color: var(--black);
  color: var(--black);
  background-color: transparent;
}



input.w-button {
  -webkit-appearance: button;
}

.link-text-wrapper {
  /* z-index: 1; */
  /* position: relative; */
  /* overflow: hidden; */
}


.link-floating-text.shadow-medium {
  text-shadow: 0 15px 0 var(--dark-black-rg);
}

.link-arrow {
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  margin-top: 1px;
  margin-left: 8px;
  display: flex;
}


.link-floating-text {
  /* text-shadow: 0 17px #000; */
  line-height: 1;
}






.contact-email-text-shadow {
  text-shadow: 0 56px #fff;
}




@media screen and (max-width: 767px) {
  .spacer._40 {
    height: 24px;
  }

  .link-floating-text {
    text-shadow: 0 15px #000;
  }

}

.spacer._40 {
  height: 40px;
}

.spacer._20 {
  height: 20px;
}

/*  */

/* footer */
.section.footer {
  /* background-color: var(--black); */
  color: var(--white);
  position: relative;
}




@media screen and (max-width: 991px) {
  .container_nav.footer {
    padding-top: 64px;
    padding-bottom: 32px;
  }
}

.container_nav.footer {
  padding-top: 6.5em;
  padding-bottom: 2em;
}


@media screen and (max-width: 479px) {


  .link-floating-text.contact-email-text-shadow {
    font-size: 42px;
  }


}



@media screen and (max-width: 479px) {
  .w-container_nav {
    max-width: none;
  }

  .container_nav.nav {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .w-container_nav {
    max-width: 728px;
  }
}

.w-container_nav {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.grid_foot {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  position: relative;
}


.grid-item_foot {
  width: 100%;
  position: relative;
}


@media screen and (max-width: 767px) {
  .footer-contact-wrapper.animation-reveal-2 {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 991px) {
  .footer-contact-wrapper.animation-reveal-2 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.footer-contact-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* padding-top: 12em; */
  padding-bottom: 12em;
  display: flex;
}


.flex-horizontal.justify-space-between.align-baseline {
  align-items: baseline;
}

.flex-horizontal.align-bottom.justify-space-between,
.flex-horizontal.justify-space-between {
  justify-content: space-between;
}

.flex-item-33 {
  width: 33%;
}

.flex-item-33.center {
  text-align: center;
}

.flex-item-33.right {
  text-align: right;
}

.paragraph.extra-small {
  font-size: 16px;
}

.paragraph.text-color-grey-60 {
  color: var(--grey-60);
}

.text-color-grey-60 {
  color: var(--grey-60);
}

@media screen and (max-width: 479px) {
  .contact-email-link {
    text-align: center;
  }

  .grid-item_foot .paragraph.extra-small {
    font-size: 13px;
  }


}

.contact-email-link {
  font-size: 56px;
}

.overflow-hidden {
  overflow: hidden;
}



@media screen and (max-width: 479px) {
  .footer-bottom-wrapper.center-align {
    flex-flow: column;
    align-content: flex-start;
    justify-content: center;
    height: auto;
    margin-top: 0;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .footer-bottom-wrapper.center-align {
    margin-bottom: 72px;
  }
}

@media screen and (max-width: 991px) {
  .footer-bottom-wrapper.center-align {
    grid-row-gap: 12px;
    flex-flow: wrap;
    margin-bottom: 56px;
  }
}

.footer-bottom-wrapper.center-align {
  grid-column-gap: 12px;
  justify-content: center;
}

@media screen and (max-width: 479px) {
  .footer-bottom-wrapper {
    opacity: .98;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 991px) {
  .footer-bottom-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: auto;
  }
}

.footer-bottom-wrapper {
  color: var(--grey-60);
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

/* koniec footer */


/* nav */



.container_nav.nav {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
}




@media screen and (max-width: 767px) {
  .container_nav {
    /* padding: 56px 20px; */
  }

}




.w-icon-slider-left:before {
  content: ""
}

.w-icon-nav-menu:before {
  content: ""
}




.flex-vertical {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.w-nav {
  z-index: 1000;
  background: #ddd;
  position: relative
}

.w-nav:before,
.w-nav:after {
  content: " ";
  grid-area: 1/1/2/2;
  display: table
}

.w-nav:after {
  clear: both
}

.brand-logo-wrapper {
  align-items: center;
  display: flex;
}

.w-nav-brand {
  float: left;
  /* color: #333; */
  text-decoration: none;
  position: relative
}

.w-nav-link {
  vertical-align: top;
  color: #222;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-decoration: none;
  display: inline-block;
  position: relative
}

.w-nav-link.w--current {
  color: #0082f3
}

.link.nav {
  background-color: transparent;
  font-size: 14px
}

.link.nav:hover {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0
}

.link.medium {
  font-size: 14px
}

.link.extra-small {
  font-size: 11px
}

.color-block {
  background-color: var(--black);
  color: var(--white);
  border-radius: 2px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 16vw;
  padding: 20px;
  font-size: 20px;
  display: flex
}



.nav-brand {
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  transition: opacity .3s;
  display: flex
}






.nav-brand:hover {
  opacity: .6
}

.nav-brand.sg {
  color: var(--white)
}

.menu-button {
  float: none
}

.nav-link-text {
  letter-spacing: -.01em;
  text-transform: uppercase;
  text-shadow: 0 12px #000;
  padding: 0;
  transition: color .3s
}

.nav-link-text.w--current {
  box-shadow: inset 0 -1px 0 0 var(--white);
  color: var(--white)
}

.nav-link-text.text-shadow-light {
  text-shadow: 0 11px 0 var(--white)
}

.nav-link-text.text-shadow-light.non-capitilized {
  text-transform: none
}

.nav-link-text.non-capitalized {
  text-transform: none;
  display: block
}

.center-wrapper {
  text-align: center;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: flex
}

.overlay-transition {
  background-color: var(--grey-15);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%
}

/* .menu-nav {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex
} */
.menu-links-wrapper {
  grid-row-gap: 8px;
  /* -webkit-backdrop-filter: none;
    backdrop-filter: none;
    background-color: transparent; */
  flex-direction: column;
  align-items: center;
  display: flex
}



.nav-link-wrapper {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  /* overflow: hidden */
}

.social-nav_container {
  align-content: baseline;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  display: flex;
}


@media screen and (max-width: 991px) {
  .nav-link-stack.hea {
    grid-row-gap: 20px;
    align-items: center;
    margin-top: 32px;
  }
}

.nav-link-stack.hea {
  align-items: flex-end;
}

.nav-link-stack {
  grid-row-gap: 6px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

@media screen and (min-width: 1118px) {
  .nav-link-stack_soc {
    display: none;
  }


  .justify-between-menu {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1117px) {
  .nav-link-stack_soc.he_soc {
    grid-row-gap: 20px;
    align-items: center;
    margin-top: 32px;
  }

  .nav-link-text.he_soc {

    font-size: 18px;
  }

  .nav-social-links-wrapper2 {

    display: flex;
  }

  .nav-link-stack_soc {
    grid-row-gap: 6px;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav-link-text2 {
    /* padding: 0 10px; */
    font-size: 18px;
  }

  .nav-social-links-wrapper2 {
    grid-column-gap: 20px;
  }
}





.nav-link_social {
  display: flex;
  /* align-items: center; */
}



.nav-link-wrapper.w--current {
  color: var(--grey-60)
}

.inner-title-wrapper {
  width: 50%
}

.social-nav.small {
  font-size: 12px
}

.social-nav.text-color-grey-60 {
  color: var(--grey-60)
}

.nav-social-links-wrapper {
  grid-column-gap: 12px;
  display: flex;
}

.nav-location {
  color: var(--grey-40);
  font-size: 12px
}

.w-nav-menu {
  float: right;
  position: relative
}







.w--nav-link-open {
  display: block;
  position: relative
}

.w-nav-overlay {
  width: 100%;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden
}

.w-nav-overlay {
  top: 0
}





.w-nav-button {
  float: right;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 18px;
  font-size: 24px;
  display: none;
  position: relative
}

.w-nav-button:focus {
  outline: 0
}

.w-nav-button.w--open {
  color: #fff;
  background-color: #c8c8c8
}



.w-nav .w-nav-button,
.w--nav-dropdown-open,
.w--nav-dropdown-toggle-open {
  display: block
}

.w--nav-dropdown-list-open {
  position: static
}






@media screen and (max-width: 991px) {
  .container_nav.nav {
    padding-top: 8px;
    padding-bottom: 8px;

    position: static;
  }

  .container_nav {
    padding: 72px 24px;
  }

  .navigation {
    z-index: 9999;
    background-color: var(--brand--bg);
    mix-blend-mode: normal;
    position: fixed;
  }

  .main-grid {
    grid-template-columns: .5fr .75fr;
  }

  .menu-nav {
    background-color: var(--brand--bg);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 80px 24px 40px;
    display: flex;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: auto;
  }

  .nav-brand {
    color: var(--black);
    height: 20px;
    margin-right: 0;
    font-size: 16px;
  }

  .brand-logo-wrapper {
    z-index: 999;
    position: relative;
  }

  .nav-location {
    font-size: 10px;
  }

  .w-nav .w-nav-menu {
    display: none;
  }



  .menu-button {
    justify-content: center;
    align-items: center;
    padding: 0 6px;
    display: flex;
  }

  .w-nav .w-nav-menu {
    display: none
  }

  .w-nav .w-nav-button {
    display: block
  }





}


@media screen and (max-width: 767px) {
  .container_nav.nav {
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .main-grid {
    grid-column-gap: 16px;
  }

  .nav-brand {
    padding-left: 0;
  }

  .w-nav-brand {
    /* padding-left: 10px; */
  }

  .menu-nav {
    padding-left: 20px;
    padding-right: 20px;
  }

  .w-nav .w-nav-menu {
    display: none
  }

  .w-nav[data-collapse=small] .w-nav-button {
    display: block
  }

  .w-nav-brand {
    /* padding-left: 10px */
  }
}

@media screen and (max-width: 479px) {
  .w-nav .w-nav-menu {
    display: none
  }

  .w-nav .w-nav-button {
    display: block
  }

}

@media screen and (max-width: 479px) {
  .container_nav.nav {
    width: 100%;
  }

  .container_nav {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .main-grid {
    grid-template-columns: 1fr;
  }
}

/*  */


.image_wrap {
  object-fit: fill;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.cover.small {
  -webkit-text-fill-color: inherit;
  object-fit: cover;
  /* background-image: url(https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg); */
  /* background-position: 50%;
    background-size: contain;
    background-attachment: scroll;
    background-clip: border-box; */
  height: 30vw;
}

.cover {
  object-fit: fill;
  width: 100%;
  max-width: none;
  margin-bottom: 0;
  font-size: 1px;
  display: block;
  overflow: visible;
}


/*  */

.scroll-smooth {
  scroll-behavior: smooth;
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-y {
  scroll-snap-type: y var(--tw-scroll-snap-strictness);
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.relative {
  position: relative;
}



.flex-auto {
  flex: 1 1 auto;
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99/var(--tw-text-opacity))
}



.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pt-10 {
  padding-top: 2.5rem;
}
.pt-16 {
  padding-top: 4rem
}
.pb-4 {
  padding-bottom: 1rem;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.p-4 {

  padding: 1rem;

}

.pt-4 {
  padding-top: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-16 {
  margin-bottom: 4rem
}

.mt-8 {
  margin-top: 2rem;
}

.mt-32 {
  margin-top: 8rem;
}

.justify-center {
  justify-content: center
}

.containerJ {
  width: 100%;
}




.self-start {
  align-self: flex-start
}



@media (min-width: 640px) {
  .containerJ {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .containerJ {
    max-width: 768px
  }

  .self-end {
    align-self: flex-end
  }

}

@media (min-width: 1024px) {
  .containerJ {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .containerJ {
    max-width: 1280px
  }

  .my-10a {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

}

@media (min-width: 1536px) {
  .containerJ {
    max-width: 1536px
  }
}

@media (min-width: 768px) {
  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .flex-auto {
    flex: 1 1 0%;
  }

  .md\:flex-1 {
    flex: 1 1 0%;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}




.h1 {
  line-height: 1;
  font-size: 5.5rem !important;
  font-weight: 600;
}

.hidden-mobile {
  display: block;
}

.flex {
  display: flex;
}

@media (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}

.hidden {
  display: none;
}


.w-24 {
  width: 6rem;
}

.h-24 {
  height: 6rem;
}

.text-circle__element {
  animation: spinZ 20s linear infinite;
  border-radius: 50%;
  color: currentColor;
  font-size: 10px;
  height: 100%;
  text-align: center;
  width: 100%
}

.text-circle__element span {
  bottom: 0;
  display: inline-block;
  font-size: 9px;
  left: 0;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 0;
  transition: all .5s cubic-bezier(0, 0, 0, 1)
}

.ml-auto {
  margin-left: auto
}

.max-w-xl {
  max-width: 36rem
}


@keyframes spinZ {
  to {
    transform: rotate(1turn)
  }
}

/*  */


@media only screen and (min-width: 1366px) {
  .banner .containerAW {
    min-height: 809px;
  }

  .heading-main {
    margin-bottom: 45px;
  }

  /* .banner-text h1 {
    font-size: 10rem;
    letter-spacing: -1.5px;
    word-spacing: -6px;
    max-width: 800px;
} */
  .heading-main {
    margin-bottom: 45px;
  }
}

.banner .containerAW {
  min-height: 641px;
  position: relative;
}

.containerAW {
  max-width: 1280px;

  /* padding: 0 80px; */

  width: 100%;
  /* max-width: 1230px; */

  /* padding-left: 20px;
    padding-right: 20px;   */
}

@media only screen and (min-width: 768px) {
  /* .banner-text {
    padding: 0 10px;
    bottom: 56px;
} */

  /* .heading-main {
    margin-bottom: 45px;
} */
  /* body .banner-text h1 {
    max-width: 900px !important;
} */
  /* .banner-text h1 {
    max-width: 435px;
} */
  /* .heading-main {
    margin-bottom: 45px;
} */
}

.banner-text {
  /* position: absolute;
    left: 0;
    bottom: 26px;
    width: 100%; */
  /* padding: 0 20px; */
}



/* .heading-main {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 24px;
    margin-bottom: 30px;
} */


@media only screen and (min-width: 375px) {
  .body__titleDI {

    font-size: 6.5rem;
    line-height: 1.0;
  }
}

/* .banner-text h1 {
    margin-bottom: 0;
    letter-spacing: -.5px;
    max-width: 280px;
}
.banner-text h1 {
    margin-bottom: 0;
    letter-spacing: -.5px;
    max-width: 280px;
}
@media only screen and (min-width: 375px) { 
.banner-text h1 {
    max-width: 300px;
    letter-spacing: -.8px;
} 
 
}
@media only screen and (min-width: 576px) { 
.banner-text h1 {
    max-width: 360px;
    letter-spacing: -1px;
    word-spacing: -2px;
    font-size: 5.5rem;
}} */



/* .heading-main {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 24px;
    margin-bottom: 30px;
} */



.animate {
  animation: 1s ease 0s 1 normal forwards move;
}

.heading-largeRG {
  letter-spacing: -.08em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4rem;
  font-weight: 300;
  line-height: .85;
}


/*  */

.flex-col {
  flex-direction: column
}

.gap-y-4 {
  row-gap: 1rem
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content
}

.pb-\[175px\] {
  padding-bottom: 175px
}

.grid {
  display: grid
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.gap-16 {
  gap: 4rem
}

.pt-6 {
  padding-top: 1.5rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.image-sec {
  max-width: 100%;
  height: auto
}


.pb-8 {
  padding-bottom: 2rem
}

.max-w-\[560px\] {
  max-width: 560px
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }
}


/*  */




.aaa {
  width: 100%;
}


.nav-title-wrap .hid,
.nav-title-wrap .main {
  /* transition: 1s cubic-bezier(.075,.82,.165,1); */
  transition: .5s cubic-bezier(.65, .05, .36, 1);
  /* transition:  transform .5s cubic-bezier(.8,0,.2,1);  */
  /* transition: transform .75s cubic-bezier(.19,1,.22,1); */
}

.p-normal {
  /* font-size: calc(1.2875rem + .12632vw); */

}

.p-normal {

  line-height: 1.2em;
  letter-spacing: 0;
}




.nav-title-wrap .hid {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-120%) scaleY(1.5);
  width: 100%;
}

.nav-title-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.nav-title-wrap:hover .hid {
  transform: translateY(0) scaleY(1);
}

.nav-title-wrap:hover .main {
  transform: translateY(120%) scaleY(1.5);

}



.p-normal {
  display: inline-block;
}




@media (max-width: 960px) {

  .small-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}




.align-center {
  justify-content: center;
}

.image-array.scale {
  margin-bottom: 10.5263157895vw;
}










/* button */

.Button_container {
  /* font-size: 15px; */
  justify-content: center;
  display: flex;

  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.Button_inner {
  /* position: relative */
}

.Button_mask {
  /* position: absolute; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px 12px;
  border-radius: 999px
}



.Button_top {
  background-color: var(--black-rg);
  color: #f0f0f0
}






@media screen and (max-width: 480px) {
  .Button_mask {
    position: relative;
    padding: 8px 16px
  }


}

@media screen and (min-width: 1921px) {
  .Button_mask_ {
    padding: 8px 16px
  }


}


/*  */


/*  */
.items-start {
  align-items: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* .-mbb-10 {
    margin-bottom: -1rem;
} */


.mbb-10 {
  margin-bottom: 1rem;
}

.mrr-10 {
  margin-right: 1rem;
}


.btnOC--small {
  /* padding: 0.8rem 1.2rem; */
  padding: 0.5rem 0.8rem;
}


.btnOC--defaultdark {
  color: #fff;
  background: transparent;
  box-shadow: inset 0 0 0 1px #fff;
}

.btnOC--defaultdark:hover {
  color: #212121;
  box-shadow: inset 0 0 0 1px #fff;
}

.btnOC--defaultdark:after {
  background: #fff;
}



.btnOC--default {
  color: #212121;
  background: transparent;
  box-shadow: inset 0 0 0 1px #212121;
}

.btnOC {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  cursor: pointer;


  line-height: 1;
  font-weight: 400;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transition-duration: .3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 9999px;
  /* box-shadow: inset 0 0 0 1px transparent; */
  will-change: transform;
  overflow: hidden;
  /* text-transform: uppercase; */
}

.btnOC>* {
  position: relative;
  z-index: 2;
}

.btnOC__icon,
.btn__text {
  position: relative;
  z-index: 3;
}


.btnOC--default:after {
  background: #212121;
}

.btnOC:after {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transition-duration: .7s;
  content: "";
  position: absolute;
  border-radius: 50%;
  left: -3rem;
  right: -3rem;
  top: -100%;
  bottom: -100%;
  transform-origin: top;
  transform: scaleY(0);
  will-change: transform;
}

.btnOC--default:hover {
  color: #fff;
  box-shadow: inset 0 0 0 1px #212121;
}

.btnOC:hover:after {

  transform-origin: bottom;
  transform: scaleY(1);

}

/*  */


.Form_input__653_E:focus+.Form_label__HqQ1f {
  color: #949494;
  transform: translateY(-100%) scale(.75)
}


.Form_input__653_E {
  width: 100%;
  height: auto;
  padding-top: 24px;
  padding-bottom: 8px;
}

.Form_inputs__NaeI4 {
  width: 0;
  height: 0;
  opacity: 0
}

.Form_label__HqQ1f {
  pointer-events: none;
  position: absolute;
  top: 24px;
  margin-right: 0;
  margin-bottom: 0;
  transform-origin: 0 0;
  transition: .15s;
  font-weight: 400
}


.Form_lineWrap__tN7k3 {
  position: relative;
  width: 100%;
  height: 1px;
  overflow: hidden;
  margin-top: -1px;
  background-color: #d6d6d6
}

.Form_line__EwAjJ {
  width: 100%;
  height: 100%;
  /* background-color: #0f0f0f; */
  transform: translateX(-100%)
}

/* @media screen and (min-width: 1441px) { 
.Text_XS__8vyc3 {
    font-size: 1vw;
}}
@media screen and (min-width: 1281px) { 
.Text_XS__8vyc3 {
    font-size: 14px;
}}
.Text_XS__8vyc3 {
    font-size: 14px;
}
.Text_XS__8vyc3, .Text_XXS__Co_Sd {
    letter-spacing: -.02em;
    margin-left: -0.02em;
}
.Text_L__IJAId, .Text_ML__9porR, .Text_M__tK_lp, .Text_S__ir2YG, .Text_XL__ku5Fh, .Text_XS__8vyc3, .Text_XXS__Co_Sd {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: -.03em;
    margin-left: -0.03em;
} */


/* .Form_validate__kSHnd {
    color: #ea3f3f;
    position: absolute;
    top: 100%
} */

input,
textarea {
  display: block;
  box-sizing: border-box;
  border-radius: 0;
  outline: none;

  /* display: inline-block; */
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  background-color: field;
  margin: 0em;
  padding: 1px 0px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
  padding-block: 1px;
  padding-inline: 2px;
}

textarea {
  display: block;
  box-sizing: border-box;
  border-radius: 0;
  border: none;
  outline: none;
  /* background: 0 0; */
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}


input::placeholder {
  color: transparent
}

.Form_formGrid__cFE_P textarea::placeholder {
  color: transparent
}

/* .Form_select__SI2X2 .Form_label__HqQ1f.Form_selected__opzpP {
    color: #949494!important;
    transform: translateY(-100%) scale(.75)!important
} */
.Form_input__653_E:focus+.Form_label__HqQ1f {
  color: #949494;
  transform: translateY(-100%) scale(.75);
}

.Form_formGrid__cFE_P .Form_message__URb70:not(.Form_inputs__NaeI4) {
  grid-column: 1 / span 2;
}

.Form_field__5UEwb {
  position: relative;
  height: 100%;
}


.Form_formGrid__cFE_P {
  display: grid;
  grid-column-gap: 2vw;
  grid-row-gap: 2vw;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 480px) {

  .Form_formGrid__cFE_P {
    grid-template-columns: 1fr;
  }
}

input:focus,
textarea:focus {
  outline: 0;
}



/* cookie  */

.cookies-hold {
  position: fixed;
  z-index: 300;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  width: calc(100% - 48px);
  max-width: 410px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 24px rgba(0, 0, 0, .08);
  -moz-box-shadow: 0px 0px 24px rgba(0, 0, 0, .08);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, .08);
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px;
  padding-left: 34px;
}

.cookies-text {
  color: rgba(0, 0, 0, .7);
  font-size: 17px;
  line-height: 1.65;
  letter-spacing: .01em;
  padding-right: 50px;
}

.cookies-text a {
  color: #000;
  font-weight: 700;
}

.btn-black,
.btn-black:focus,
.btn-black:active {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  padding: 7px 24px;
  background: #000;
  color: #fff;
  border-radius: 21px;
  font-size: 14px;
  line-height: 2;
  letter-spacing: .04em;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}




.btn-black span {
  white-space: nowrap;
  transition: transform .6s cubic-bezier(.25, .74, .22, .99);
  /* transition: transform .75s cubic-bezier(.19,1,.22,1); */
}

.cookies-text a:hover {
  text-decoration: underline;
  color: #000;

}

.btn-black:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  height: 100%;
  border-top: 10px solid #000;
  border-bottom: 10px solid #000;
  z-index: 10;
}

.btn-black:after {
  content: attr(data-btn);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 50px));
  white-space: nowrap;
  transition: transform .6s cubic-bezier(.25, .74, .22, .99);
}

.btn-black:hover span {
  transform: translateY(-50px);
}

.btn-black:hover:after {
  transform: translate(-50%, calc(-50% + 0px));
}





.btn-black:hover {
  background: #000;
  color: #fff;
}

/* cookies koniec  */

.swipe {
  position: relative;
  height: 100%;
}

.swipe .swiper {
  width: 100%;
  /* padding-top: 50px;
    padding-bottom: 50px; */
}

.swipe .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 500px;
  height: 500px;
}

.swipe .swiper-slide img,
.swipe .swiper-slide picture {
  display: block;
  width: 100%;

}

.gallery-work img,
.swipe .swiper-slide picture {
  object-fit: cover;
  object-position: 50% 40%;
  border-radius: 0;
  width: 100%;
  height: 100%;
  display: block;

}

.swipe .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, black);
}


@media (min-width: 768px) {
  .swipe .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.swipe .py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.swipe .mb-8 {
  margin-bottom: 2rem;
}

.swipe .mb-16 {
  margin-bottom: 4rem
}

.black-bg_color {
  background-color: var(--black);
  text-align: center;
}



.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

/* .border-\[\#1e1e1e\] {
  
  --tw-border-opacity: 1;
  border-color: rgb(30 30 30 / var(--tw-border-opacity));  
} */
.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

hr {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

/* kontakt */
.container-fluid {
  --bs-gutter-x: ;

  width: 100%;
  /* padding-right:  .75rem ;
  padding-left:   .75rem ; */
  margin-right: auto;
  margin-left: auto;
}

.pyCn-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}



.form-container {
  /* padding: 40px 0 140px; */
  flex-wrap: wrap;
  display: flex
}

@media screen and (max-width: 1659px) {
  .form-container {
    /* padding:100px 0 */
  }
}

@media screen and (max-width: 1199px) {
  .form-container {
    /* padding:80px 0 */
  }
}

@media screen and (max-width: 1023px) {
  .form-container {
    /* padding:80px 0 60px */
  }
}

@media screen and (max-width: 767px) {
  .form-container {
    /* padding:0px 0 0px; */
    display: block
  }
}

.form-container__heading {
  flex: 0 0 100%;
  margin: 0 0 100px
}

@media screen and (max-width: 1659px) {
  .form-container__heading {
    margin: 0 0 80px
  }
}

@media screen and (max-width: 1199px) {
  .form-container__heading {
    margin: 0 0 60px
  }
}

@media screen and (max-width: 767px) {
  .form-container__heading {
    margin: 0 0 50px
  }

  .form-container__heading h1 {
    max-width: 100%;

  }
}



.form-container__heading h1 {
  max-width: 70%;

}

.h1-c {
  font-size: 5rem;
  line-height: 1;
}

@media screen and (max-width: 1659px) {
  .form-container__heading h1 {
    max-width: 50%;

  }

  .h1-c {
    font-size: calc(1.575rem + 3.9vw);
    line-height: 1;
  }
}

@media screen and (max-width: 1199px) {
  .form-container__heading h1 {
    max-width: 75%;

  }

  .h1-c {
    font-size: calc(1.525rem + 3.3vw);
    ;
    line-height: 1;

  }
}


@media screen and (max-width: 767px) {


  .form-container__heading h1 {
    max-width: 100%;

  }
}



.form-container__left {
  flex: 0 0 45%
}

.form-container__avatar {
  margin: 0 0 44px
}

.form-container__avatar img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  margin: 0
}

.form-container__text {
  max-width: 60%
}

@media screen and (max-width: 1199px) {
  .form-container__text {
    max-width: 65%
  }
}

@media screen and (max-width: 1023px) {
  .form-container__text {
    max-width: 80%
  }
}

@media screen and (max-width: 767px) {
  .form-container__text {
    max-width: 100%;
    margin: 0 0 60px
  }
}

/* .form-container__text>* {
    margin: 34px 0
} */

.form-container__text>:first-child {
  margin-top: 0 !important
}

.form-container__text>:last-child {
  margin-bottom: 0 !important
}

.form-container__text p {
  font-size: 20px;
  line-height: 160%
}

@media screen and (max-width: 1199px) {
  .form-container__text p {
    font-size: 18px
  }
}

.form-container__text a {
  text-decoration: underline;
  font-size: inherit;
  font-weight: 600;
  line-height: inherit;
  color: #000
}

.form-container__right {
  flex: 0 0 55%
}

.form-container .wpcf7-response-output {
  max-width: 80%;
  margin: 0 0 60px !important;
  padding: 12px 20px !important
}

@media screen and (max-width: 1659px) {
  .form-container .wpcf7-response-output {
    max-width: 85%
  }
}

@media screen and (max-width: 1199px) {
  .form-container .wpcf7-response-output {
    max-width: unset
  }
}

.contact-form {
  max-width: 80%;
  flex-direction: column;
  gap: 14px;
  display: flex
}

@media screen and (max-width: 1659px) {
  .contact-form {
    max-width: 85%
  }
}

@media screen and (max-width: 1199px) {
  .contact-form {
    max-width: unset;
    gap: 10px
  }
}

.contact-form__input label {
  font-size: 16px;
  line-height: 140%;
  margin: 0 0 14px;
  display: block
}

@media screen and (max-width: 1199px) {
  .contact-form__input label {
    margin: 0 0 12px
  }
}

.contact-form__input input,
.contact-form__input textarea {
  border: 2px solid rgba(0, 0, 0, .25);
  width: 100%;
  height: 70px;
  font-size: 20px;
  line-height: 1;
  padding: 0 20px;
  outline: none !important;
  box-shadow: none !important;
  -webkit-appearance: none
}

@media screen and (max-width: 1659px) {

  .contact-form__input input,
  .contact-form__input textarea {
    height: 64px
  }
}

@media screen and (max-width: 1199px) {

  .contact-form__input input,
  .contact-form__input textarea {
    height: 56px;
    font-size: 18px
  }
}

.contact-form__input textarea {
  height: 200px;
  line-height: 140%;
  padding: 16px 20px
}

@media screen and (max-width: 1659px) {
  .contact-form__input textarea {
    height: 175px
  }
}

@media screen and (max-width: 1199px) {
  .contact-form__input textarea {
    height: 150px
  }
}

.contact-form__input br {
  display: none
}

.contact-form__input .wpcf7-not-valid-tip {
  font-size: 14px;
  line-height: 140%;
  margin: 6px 0 0
}

.contact-form__submit {
  padding: 10px 0 0
}

.contact-form__submit input {
  border: 0 !important;
  border-radius: 0;
  background-color: #000;
  width: 100%;
  height: 70px;
  font-size: 20px;
  line-height: 1;
  color: #fff;
  outline: none !important;
  box-shadow: none !important;
  -webkit-appearance: none
}

@media screen and (max-width: 1659px) {
  .contact-form__submit input {
    height: 64px
  }
}

@media screen and (max-width: 1199px) {
  .contact-form__submit input {
    height: 56px;
    font-size: 18px
  }
}

/* button send */
.send {
  width: 100%;
  color: #f5f5f5;
  text-align: center;
  --box-shadow2: 0 1.4rem 8rem #0003;
  cursor: pointer;
  background: #131417;
  border: none;
  border-radius: 2px;
  justify-content: center;
  margin: auto;
  padding: .5em 1em .5em .9em;
  font-family: inherit;
  font-size: 1.1rem;
  transition: all .2s;
  display: flex;
  overflow: hidden;
}

.send .text {
  margin: 2px;
  transition: all .3s ease-in-out;
  display: block;
}

.send:hover svg {
  transform: translate(1.2em) rotate(45deg) scale(1.1);
}

.send svg {
  transform-origin: 50%;
  transition: transform .3s ease-in-out;
  display: block;
}

.send:hover .svg-wrapper {
  animation: .6s ease-in-out infinite alternate fly-1;
}


@keyframes fly-1 {
  0% {
    transform: translateY(.1em);
  }

  100% {
    transform: translateY(-.1em);
  }
}

.svg-wrapper,
.svg-wrapper-1 {
  display: block;
}

.send:hover .text {
  transform: translate(19em);
}

.send .text {
  margin: 2px;
  transition: all .3s ease-in-out;
  display: block;
}

/* button send */





/* koniec kontakt */


/* cookies */

.breadcrumbs {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  font-size: 1.2rem;
  display: block;
}

.list_bread {
  vertical-align: middle;
  width: 100%;
  display: inline-block;
}

.breadcrumbs ol {
  white-space: normal;
  font-size: 1rem;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.breadcrumbs li:before {
  content: " ";
  -webkit-text-decoration: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  padding: 0 7px;
  font-family: FontAwesome;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}

/* koniec cookies */


/* swiper gallery  */


/* .gallery .swiper {
    width: 100%;
    height: 100%;
  }

  .gallery .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

.gallery .swiper-slide img {
  /* display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; */
}


.gallery .swiper {
  width: 100%;
  height: 100%;
}

.gallery .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery .swiper-slide img {
  display: block;
  /* width: 100%;
    height: 100%; */
  object-fit: cover;
}



.gallery .swiper {
  cursor: grab;
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.gallery .swiper-slide {
  background-size: cover;
  background-position: center;
}

.gallery .mySwiper2 {
  height: 80%;
  width: 100%;
}

.gallery .mySwiper {
  height: 10%;
  box-sizing: border-box;
  padding: 10px 0;
}

.gallery .mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.gallery .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.gallery .swiper-button-next,
.gallery .swiper-button-prev {
  --swiper-navigation-size: 34px;
  --swiper-navigation-color: #828283;
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44* 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color);
}


@media screen and (min-width: 1024px) {

  #swiper7 .swiper-button-next,
  #swiper7 .swiper-button-prev {

    display: none;


  }
}



.gallery .justify-center {
  justify-content: center
}


.gridem {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  position: relative;
}

/* koniec swiper gallery  */



/*  */



/*  */


.lineh-no {
  line-height: 1;
}
