:root {
  --grey-rg: #d7d7d7;
  --grey-rg1: #aaadb0;
  --grey-rg2: #c4c4c4;
  --grey-rg3: #ccc;
  --black-rg: #131417;
  --black-rg2: #3b3c41;
  --whitish-rg: #fafafa;
  --pure-white-rg: white;
  --off-white-rg: #fff6;
  --blue-rg: #0d65ec;
  --blue-light-rg: #a7c1da;
  --green-rg: #1ba372;
  --green-stroke-rg: #68a28d;
  --dark-black-rg: #141414;
  --color-stroke-rg: #9d9d9d;
  --light-violet: #c5c4d3;
  --lime-rg: #cddcc3;
  --lila-rg: #83668c;
  --lila-dark-rg: #596385;
  --brand-bg: #fafafa;
  --black: #131417;
  --brand-primary: #142218;
  --grey-80: #4d4e50;
  --white: white;
  --grey-15: #ecedf1;
  --white-50: #ffffff80;
  --grey-8-2: #f8f9fa;
  --grey-60: #828283;
  --grey-40: #9fa0a3;
  --grey-20: #cfd1d7;
  --white-25: #ffffff40;
  --white-75: #ffffffbf;
  --brand-secondary: #c0e3d1;
  --brand--tertiary: #93887c;
  --brand--hero-inner-page: #f9f3ee;
  --white-lobo: #f8f5ef;
  --warning: red;
  --gray: #979797;
  --letter01: #6b64ee;
  --letter02: #11cee9;
  --letter03: #ab6bdb;
  --letter04: #faa;
  --letter05: #90d63a;
  --letter06: #ff9f48;
  --letter07: #6c69eb;
  --letter08: #56549c;
  --letter09: #e369eb;
}

.container-FL {
  box-sizing: border-box;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

@media (max-width: 1366px) {
  .container-FL {
    max-width: 1140px;
  }
}

@media (max-width: 1199px) {
  .container-FL {
    max-width: 908px;
  }
}

@media (max-width: 910px) {
  .container-FL {
    max-width: 676px;
  }
}

@media (max-width: 767px) {
  .container-FL {
    max-width: 492px;
  }
}

@media (max-width: 480px) {
  .container-FL {
    max-width: 343px;
  }
}

.s-container-FL {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}

@media (max-width: 1366px) {
  .s-container-FL {
    max-width: 1140px;
    padding: 0;
  }
}

@media (max-width: 1199px) {
  .s-container-FL {
    max-width: 908px;
  }
}

@media (max-width: 910px) {
  .s-containerv {
    max-width: 676px;
  }
}

@media (max-width: 767px) {
  .s-container-FL {
    max-width: 492px;
  }
}

@media (max-width: 480px) {
  .s-container-FL {
    max-width: 343px;
  }
}

.m-container-FL {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px;
}

@media (max-width: 1366px) {
  .m-container-FL {
    padding: 0 30px;
  }
}

@media (max-width: 1199px) {
  .m-container-FL {
    padding: 0 42px;
  }
}

@media (max-width: 991px) {
  .m-container-FL {
    padding: 0 46px;
  }
}

@media (max-width: 767px) {
  .m-container-FL {
    padding: 0 42px;
  }
}

@media (max-width: 575px) {
  .m-container-FL {
    padding: 0 16px;
  }
}

.custom-popup .vm--modal {
  background: #fff;
  border-radius: 46px;
  overflow-y: auto;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  color: var(--black-rg);
  background-color: var(--whitish-rg);
  padding-top: 5rem;
  font-family: Manrope, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  overflow-x: hidden;
}

* {
  scroll-behavior: smooth;
}

*, p {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
}

.header.fixed {
  border-color: var(--black-rg2);
}

@media (max-width: 767px) {
  .header.fixed .header__language {
    padding: 7px 11px;
  }
}

.home::-webkit-scrollbar {
  display: none;
}

.home {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
}

.bg-dark {
  background-color: var(--black-rg);
  color: var(--whitish-rg);
}

.bg-darker {
  color: var(--whitish-rg);
  background-color: #121212;
}

.bg-whit {
  background-color: var(--whitish-rg);
  color: var(--black-rg);
}

.areas-block {
  margin-top: -40px;
}

.container-left, .container-right {
  border-top: 1px solid #121212;
}

.container-right {
  border-bottom: 1px solid #121212;
}

.body__button-info p {
  letter-spacing: .02em;
  text-transform: uppercase;
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: lighter;
  line-height: 150%;
}

.prose__description {
  align-items: flex-end;
  display: flex;
}

@media (max-width: 600px) {
  .prose__description {
    grid-row-gap: 14px;
    flex-direction: column;
    row-gap: 14px;
  }
}

@media (max-width: 767px) {
  .body__titleDI {
    font-weight: 600;
    line-height: 1;
    font-size: 3rem !important;
  }
}

@media (max-width: 1118px) {
  .mobiletext {
    text-align: center;
  }
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.body__link {
  cursor: pointer;
  width: 100%;
}

.prose {
  width: 100%;
}

@media (max-width: 1366px) {
  .prose {
    padding: 48px 0 92px;
  }
}

@media (max-width: 910px) {
  .prose {
    padding: 34px 0 112px;
  }
}

@media (max-width: 767px) {
  .prose {
    padding: 34px 0 140px;
  }
}

@media (max-width: 480px) {
  .prose {
    padding: 16px 0 112px;
  }
}

.prose__heading {
  letter-spacing: .02em;
  text-transform: uppercase;
  border-radius: 62px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 12px 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.prose__heading.white {
  border: 1px solid var(--white);
  color: var(--whitish-rg);
}

.prose__heading.dark {
  color: var(--black-rg);
  border: 1px solid #131417;
}

@media (max-width: 1366px) {
  .prose__heading {
    letter-spacing: .04em;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }
}

@media (max-width: 910px) {
  .prose__heading {
    font-size: 14px;
    font-weight: 430;
  }
}

@media (max-width: 767px) {
  .prose__heading {
    letter-spacing: .02em;
    margin-bottom: 38px;
    font-size: 16px;
    font-weight: 500;
  }
}

@media (max-width: 480px) {
  .prose__heading {
    margin-bottom: 48px;
  }
}

.prose__info {
  grid-row-gap: 14px;
  flex-direction: column;
  row-gap: 14px;
  display: flex;
}

@media (max-width: 767px) {
  .prose__info {
    grid-row-gap: 10px;
    row-gap: 10px;
  }
}

.prose__title {
  text-transform: uppercase;
  letter-spacing: -1px;
  max-width: 470px;
  font-size: 56px;
  font-weight: 600;
  line-height: 100%;
}

@media (max-width: 1366px) {
  .prose__title {
    letter-spacing: -.02em;
    line-height: 96%;
  }
}

@media (max-width: 1100px) {
  .prose__title {
    max-width: 300px;
    font-size: 52px;
  }
}

@media (max-width: 910px) {
  .prose__title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .prose__title {
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  .prose__title {
    letter-spacing: 0;
    max-width: 341px;
    font-size: 36px;
    line-height: 100%;
  }
}

.prose__description {
  max-width: 407px;
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
}

@media (max-width: 1366px) {
  .prose__description {
    letter-spacing: .02em;
    font-size: 15px;
    line-height: 164%;
  }
}

@media (max-width: 1100px) {
  .prose__description {
    max-width: 367px;
  }
}

@media (max-width: 910px) {
  .prose__description {
    max-width: 280px;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .prose__description {
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  .prose__description {
    letter-spacing: 0;
    max-width: 341px;
    font-size: 16px;
    line-height: 150%;
  }
}

.prose__body {
  box-sizing: border-box;
  grid-column-gap: 30px;
  -moz-column-gap: 30px;
  justify-content: space-between;
  column-gap: 30px;
  width: 100%;
  display: flex;
}

@media (max-width: 767px) {
  .prose__body {
    flex-direction: column-reverse;
    align-items: center;
    height: 100%;
  }
}

.body-card__image {
  position: absolute;
  top: 30%;
  left: -109px;
}

@media (max-width: 1200px) {
  .body-card__image {
    width: 270px;
    height: 175px;
  }
}

@media (max-width: 1100px) {
  .body-card__image {
    width: 212px;
    height: 137px;
  }
}

@media (max-width: 767px) {
  .body-card__image {
    width: 180px;
    height: 117px;
    left: -40px;
  }
}

@media (max-width: 480px) {
  .body-card__image {
    width: 346px;
    height: 160px;
    left: 10px;
  }
}

.body__left {
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  display: flex;
}

@media (max-width: 767px) {
  .body__left {
    padding-top: 34px;
    padding-bottom: 34px;
  }
}

@media (max-width: 480px) {
  .body__left {
    padding-top: 34px;
    padding-bottom: 24px;
  }
}

.body__right {
  height: 100%;
  position: relative;
}

@media (max-width: 480px) {
  .body__right {
    width: 100%;
  }
}

.body__right-image {
  -o-object-fit: cover;
  object-fit: cover;
  width: 560px;
  height: 630px;
}

@media (max-width: 1200px) {
  .body__right-image {
    width: 509px;
    height: 560px;
  }
}

.obj_pos_c_30 {
  object-position: center 30%;
}

.obj_pos_c_20 {
  object-position: center 20%;
}

.obj_pos_c_10 {
  object-position: center 10%;
}

.obj_pos_center {
  object-position: center;
}

@media (max-width: 480px) {
  .body__right-image {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 450px;
  }

  .desc {
    display: none;
  }
}

.mobile {
  display: none;
}

@media (max-width: 480px) {
  .mobile {
    display: block;
  }
}

.ParallaxContainer {
  overflow: hidden;
}

.areas {
  padding: 64px 0 208px;
}

.areas.aboutme {
  padding: 6rem 0 8rem;
}

@media (max-width: 1366px) {
  .areas {
    padding: 61px 0 228px;
  }
}

@media (max-width: 760px) {
  .areas {
    padding: 42px 0 180px;
  }
}

@media (max-width: 480px) {
  .areas {
    margin-top: -56px;
    padding: 32px 0 144px;
  }
}

.areas__title {
  letter-spacing: -.6px;
  font-size: 74px;
  font-weight: 600;
  line-height: 100%;
}

@media (max-width: 1366px) {
  .areas__title {
    font-size: 72px;
    line-height: 114%;
  }
}

@media (max-width: 1199px) {
  .areas__title {
    font-size: 62px;
  }
}

@media (max-width: 990px) {
  .areas__title {
    font-size: 46px;
  }
}

@media (max-width: 760px) {
  .areas__title {
    font-size: 32px;
  }
}

@media (max-width: 480px) {
  .areas__title {
    text-align: center;
    letter-spacing: -.02em;
    font-size: 32px;
    font-weight: 500;
  }
}

.areas__headline {
  letter-spacing: -.03em;
  padding-bottom: 112px;
  font-size: 74px;
  font-weight: 600;
  line-height: 90%;
}

@media (max-width: 1366px) {
  .areas__headline {
    padding-bottom: 140px;
    font-size: 130px;
    line-height: 86%;
  }
}

@media (max-width: 1199px) {
  .areas__headline {
    padding-bottom: 68px;
    font-size: 110px;
    line-height: 86%;
  }
}

@media (max-width: 990px) {
  .areas__headline {
    padding-bottom: 94px;
    font-size: 80px;
    line-height: 86%;
  }
}

@media (max-width: 760px) {
  .areas__headline {
    text-align: center;
    padding-bottom: 58px;
    font-size: 54px;
    line-height: 86%;
  }
}

@media (max-width: 480px) {
  .areas__headline {
    padding-bottom: 64px;
    font-size: 48px;
    line-height: 48px;
  }
}

.area-content {
  grid-column-gap: 129px;
  -moz-column-gap: 129px;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 129px;
  width: 100%;
  padding-bottom: 88px;
  display: flex;
}

.aboutme .area-content {
  grid-column-gap: 129px;
  -moz-column-gap: 129px;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 129px;
  width: 100%;
  padding-bottom: 0;
  display: flex;
}

@media (max-width: 1366px) {
  .area-content {
    grid-column-gap: 115px;
    -moz-column-gap: 115px;
    column-gap: 115px;
    padding-bottom: 95px;
  }
}

@media (max-width: 1199px) {
  .area-content {
    grid-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .area-content {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-bottom: 50px;
  }

  .aboutme .area-content {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .area-content {
    padding-bottom: 32px;
  }
}

.area-content__info {
  grid-row-gap: 16px;
  flex-direction: column;
  row-gap: 16px;
  display: flex;
}

@media (max-width: 767px) {
  .area-content__info {
    grid-row-gap: 14px;
    row-gap: 14px;
    margin: auto;
    padding-top: 24px;
    display: block;
  }
}

@media (max-width: 480px) {
  .area-content__info {
    grid-row-gap: 8px;
    row-gap: 8px;
  }
}

.area-content__title {
  letter-spacing: -.01em;
  text-transform: uppercase;
  color: #fff;
  max-width: 400px;
  font-size: 74px;
  font-weight: 600;
  line-height: 100%;
}

@media (max-width: 1366px) {
  .area-content__title {
    letter-spacing: -.02em;
    font-size: 60px;
  }
}

@media (max-width: 1199px) {
  .area-content__title {
    max-width: 350px;
    font-size: 52px;
  }
}

@media (max-width: 990px) {
  .area-content__title {
    font-weight: 430;
  }
}

@media (max-width: 767px) {
  .area-content__title {
    max-width: 400px;
    font-size: 36px;
  }
}

@media (max-width: 480px) {
  .area-content__title {
    letter-spacing: -.01em;
    line-height: 100%;
  }
}

.area-content__description {
  color: #fff;
  max-width: 413px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

@media (max-width: 1366px) {
  .area-content__description {
    font-size: 15px;
    font-weight: 430;
    line-height: 180%;
  }
}

@media (max-width: 480px) {
  .area-content__description {
    max-width: 343px;
    font-weight: 500;
    line-height: 150%;
    font-size: 16px !important;
  }
}

.area-content__image {
  background-position: 50%;
  background-size: cover;
  width: 738px;
  height: 460px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1366px) {
  .area-content__image {
    width: 657px;
    height: 554px;
  }
}

@media (max-width: 1199px) {
  .area-content__image {
    width: 444px;
    height: 406px;
  }
}

@media (max-width: 990px) {
  .area-content__image {
    min-width: 328px;
    height: 378px;
  }
}

@media (max-width: 767px) {
  .area-content__image {
    width: 100%;
    height: 380px;
    margin-top: 2rem;
  }
}

.area-content__image img {
  width: 375px;
  position: absolute;
  top: 81%;
  bottom: 0;
  transform: translateX(-50%);
}

@media (max-width: 1199px) {
  .area-content__image img {
    width: 268px;
    top: 73px;
  }
}

@media (max-width: 991px) {
  .area-content__image img {
    top: 48px;
  }
}

@media (max-width: 480px) {
  .area-content__image img {
    width: 268px;
  }

  .area-content__image img.desc {
    display: none;
  }
}

@media (min-width: 479px) {
  .area-content__image img.mob {
    display: none;
  }
}

@media (max-width: 480px) {
  .area-content__image img.mob {
    top: 38px;
  }
}

.area-content.revers {
  flex-direction: row-reverse;
}

@media (max-width: 750px) {
  .area-content.revers {
    flex-direction: column-reverse;
  }
}

.area-content.light {
  grid-column-gap: 129px;
  -moz-column-gap: 129px;
  column-gap: 129px;
}

@media (max-width: 1366px) {
  .area-content.light {
    grid-column-gap: 129px;
    -moz-column-gap: 129px;
    grid-column-gap: 98px;
    -moz-column-gap: 98px;
    column-gap: 98px;
  }
}

@media (max-width: 990px) {
  .area-content.light {
    grid-column-gap: 19px;
    -moz-column-gap: 19px;
    column-gap: 19px;
  }
}

.area-content.light .area-content__title {
  font-size: 56px;
}

@media (max-width: 1366px) {
  .area-content.light .area-content__title {
    font-size: 60px;
    line-height: 94%;
  }
}

@media (max-width: 1199px) {
  .area-content.light .area-content__title {
    font-size: 52px;
  }
}

@media (max-width: 767px) {
  .area-content.light .area-content__title {
    font-size: 36px;
  }
}

.area-content.light .area-content__description {
  font-size: 18px;
}

@media (max-width: 1366px) {
  .area-content.light .area-content__description {
    font-size: 16px;
    line-height: 180%;
  }
}

@media (max-width: 1199px) {
  .area-content.light .area-content__description {
    font-size: 16px;
  }
}

.c3_card {
  box-sizing: border-box;
  border-radius: 32px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 413px;
  height: 512px;
  display: flex;
}

@media (max-width: 480px) {
  .desc {
    display: none;
  }
}

.mob {
  display: none;
}

@media (max-width: 480px) {
  .mob {
    display: block;
  }
}

.card {
  grid-column-gap: 30px;
  -moz-column-gap: 30px;
  box-sizing: border-box;
  border: 1px solid #121212;
  column-gap: 30px;
  padding: 32px 61px 32px 30px;
  display: flex;
  position: relative;
}

.gallery .card {
  box-sizing: border-box;
  border: none;
  column-gap: 0;
  padding: 0;
  display: flex;
  position: relative;
}

.input__parent {
  width: 100%;
}

.w-container:after {
  clear: both;
}

.w-container:before, .w-container:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.buttonK {
  background-color: var(--black-rg);
  color: var(--whitish-rg);
  letter-spacing: -.01em;
  text-transform: none;
  white-space: nowrap;
  border-radius: 50em;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 1;
  display: flex;
  position: relative;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.span-text-highlight {
  border: 1px solid #000;
  border-radius: 50em;
  padding: .1em .4em;
  display: inline-block;
}

.margin20 {
  margin: 20px;
}

.mtop {
  margin-top: 2rem;
}

@media screen and (max-width: 767px) {
  .submit-button {
    width: 100%;
  }

  .link-floating-text {
    text-shadow: 0 15px #000;
  }
}

.submit-button {
  z-index: 1;
  color: var(--dark-black-rg);
  text-align: center;
  letter-spacing: .05em;
  text-transform: uppercase;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  inset: 0%;
}

.w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

.color-white {
  color: var(--whitish-rg);
}

.flex-horizontal {
  align-self: stretch;
  display: flex;
}

.buttonS.submit {
  font-weight: 400;
  position: relative;
}

.buttonS {
  border: 1px solid var(--whitish-rg);
  background-color: var(--dark-black-rg);
  color: var(--whitish-rg);
  letter-spacing: -.01em;
  text-transform: none;
  white-space: nowrap;
  border-radius: 50em;
  justify-content: space-between;
  align-items: center;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1;
  display: flex;
  position: relative;
}

.button.stroke.white {
  border-color: var(--white);
  color: var(--white);
}

.button.stroke {
  border-color: var(--black);
  color: var(--black);
  background-color: #0000;
}

input.w-button {
  -webkit-appearance: button;
}

.link-floating-text.shadow-medium {
  text-shadow: 0 15px 0 var(--dark-black-rg);
}

.link-arrow {
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  margin-top: 1px;
  margin-left: 8px;
  display: flex;
}

.link-floating-text {
  line-height: 1;
}

.contact-email-text-shadow {
  text-shadow: 0 56px #fff;
}

@media screen and (max-width: 767px) {
  .spacer._40 {
    height: 24px;
  }

  .link-floating-text {
    text-shadow: 0 15px #000;
  }
}

.spacer._40 {
  height: 40px;
}

.spacer._20 {
  height: 20px;
}

.section.footer {
  color: var(--white);
  position: relative;
}

@media screen and (max-width: 991px) {
  .container_nav.footer {
    padding-top: 64px;
    padding-bottom: 32px;
  }
}

.container_nav.footer {
  padding-top: 6.5em;
  padding-bottom: 2em;
}

@media screen and (max-width: 479px) {
  .link-floating-text.contact-email-text-shadow {
    font-size: 42px;
  }

  .w-container_nav {
    max-width: none;
  }

  .container_nav.nav {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .w-container_nav {
    max-width: 728px;
  }
}

.w-container_nav {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.grid_foot {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  position: relative;
}

.grid-item_foot {
  width: 100%;
  position: relative;
}

@media screen and (max-width: 767px) {
  .footer-contact-wrapper.animation-reveal-2 {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 991px) {
  .footer-contact-wrapper.animation-reveal-2 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.footer-contact-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 12em;
  display: flex;
}

.flex-horizontal.justify-space-between.align-baseline {
  align-items: baseline;
}

.flex-horizontal.align-bottom.justify-space-between, .flex-horizontal.justify-space-between {
  justify-content: space-between;
}

.flex-item-33 {
  width: 33%;
}

.flex-item-33.center {
  text-align: center;
}

.flex-item-33.right {
  text-align: right;
}

.paragraph.extra-small {
  font-size: 16px;
}

.paragraph.text-color-grey-60, .text-color-grey-60 {
  color: var(--grey-60);
}

@media screen and (max-width: 479px) {
  .contact-email-link {
    text-align: center;
  }

  .grid-item_foot .paragraph.extra-small {
    font-size: 13px;
  }
}

.contact-email-link {
  font-size: 56px;
}

.overflow-hidden {
  overflow: hidden;
}

@media screen and (max-width: 479px) {
  .footer-bottom-wrapper.center-align {
    flex-flow: column;
    place-content: flex-start center;
    height: auto;
    margin-top: 0;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .footer-bottom-wrapper.center-align {
    margin-bottom: 72px;
  }
}

@media screen and (max-width: 991px) {
  .footer-bottom-wrapper.center-align {
    grid-row-gap: 12px;
    flex-flow: wrap;
    margin-bottom: 56px;
  }
}

.footer-bottom-wrapper.center-align {
  grid-column-gap: 12px;
  justify-content: center;
}

@media screen and (max-width: 479px) {
  .footer-bottom-wrapper {
    opacity: .98;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 991px) {
  .footer-bottom-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: auto;
  }
}

.footer-bottom-wrapper {
  color: var(--grey-60);
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

.container_nav.nav {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
}

.w-icon-slider-left:before, .w-icon-nav-menu:before {
  content: "";
}

.flex-vertical {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.w-nav {
  z-index: 1000;
  background: #ddd;
  position: relative;
}

.w-nav:before, .w-nav:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-nav:after {
  clear: both;
}

.brand-logo-wrapper {
  align-items: center;
  display: flex;
}

.w-nav-brand {
  float: left;
  text-decoration: none;
  position: relative;
}

.w-nav-link {
  vertical-align: top;
  color: #222;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.w-nav-link.w--current {
  color: #0082f3;
}

.link.nav {
  background-color: #0000;
  font-size: 14px;
}

.link.nav:hover {
  background-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.link.medium {
  font-size: 14px;
}

.link.extra-small {
  font-size: 11px;
}

.color-block {
  background-color: var(--black);
  color: var(--white);
  border-radius: 2px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 16vw;
  padding: 20px;
  font-size: 20px;
  display: flex;
}

.nav-brand {
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  transition: opacity .3s;
  display: flex;
}

.nav-brand:hover {
  opacity: .6;
}

.nav-brand.sg {
  color: var(--white);
}

.menu-button {
  float: none;
}

.nav-link-text {
  letter-spacing: -.01em;
  text-transform: uppercase;
  text-shadow: 0 12px #000;
  padding: 0;
  transition: color .3s;
}

.nav-link-text.w--current {
  box-shadow: inset 0 -1px 0 0 var(--white);
  color: var(--white);
}

.nav-link-text.text-shadow-light {
  text-shadow: 0 11px 0 var(--white);
}

.nav-link-text.text-shadow-light.non-capitilized {
  text-transform: none;
}

.nav-link-text.non-capitalized {
  text-transform: none;
  display: block;
}

.center-wrapper {
  text-align: center;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.overlay-transition {
  background-color: var(--grey-15);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  inset: 0%;
}

.menu-links-wrapper {
  grid-row-gap: 8px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.nav-link-wrapper {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}

.social-nav_container {
  flex-direction: column;
  place-content: baseline center;
  align-items: flex-end;
  width: 100%;
  display: flex;
}

@media screen and (max-width: 991px) {
  .nav-link-stack.hea {
    grid-row-gap: 20px;
    align-items: center;
    margin-top: 32px;
  }
}

.nav-link-stack.hea {
  align-items: flex-end;
}

.nav-link-stack {
  grid-row-gap: 6px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

@media screen and (min-width: 1118px) {
  .nav-link-stack_soc {
    display: none;
  }

  .justify-between-menu {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1117px) {
  .nav-link-stack_soc.he_soc {
    grid-row-gap: 20px;
    align-items: center;
    margin-top: 32px;
  }

  .nav-link-text.he_soc {
    font-size: 18px;
  }

  .nav-social-links-wrapper2 {
    display: flex;
  }

  .nav-link-stack_soc {
    grid-row-gap: 6px;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav-link-text2 {
    font-size: 18px;
  }

  .nav-social-links-wrapper2 {
    grid-column-gap: 20px;
  }
}

.nav-link_social {
  display: flex;
}

.nav-link-wrapper.w--current {
  color: var(--grey-60);
}

.inner-title-wrapper {
  width: 50%;
}

.social-nav.small {
  font-size: 12px;
}

.social-nav.text-color-grey-60 {
  color: var(--grey-60);
}

.nav-social-links-wrapper {
  grid-column-gap: 12px;
  display: flex;
}

.nav-location {
  color: var(--grey-40);
  font-size: 12px;
}

.w-nav-menu {
  float: right;
  position: relative;
}

.w--nav-link-open {
  display: block;
  position: relative;
}

.w-nav-overlay {
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.w-nav-button {
  float: right;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  padding: 18px;
  font-size: 24px;
  display: none;
  position: relative;
}

.w-nav-button:focus {
  outline: 0;
}

.w-nav-button.w--open {
  color: #fff;
  background-color: #c8c8c8;
}

.w-nav .w-nav-button, .w--nav-dropdown-open, .w--nav-dropdown-toggle-open {
  display: block;
}

.w--nav-dropdown-list-open {
  position: static;
}

@media screen and (max-width: 991px) {
  .container_nav.nav {
    padding-top: 8px;
    padding-bottom: 8px;
    position: static;
  }

  .container_nav {
    padding: 72px 24px;
  }

  .navigation {
    z-index: 9999;
    background-color: var(--brand--bg);
    mix-blend-mode: normal;
    position: fixed;
  }

  .main-grid {
    grid-template-columns: .5fr .75fr;
  }

  .menu-nav {
    background-color: var(--brand--bg);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 80px 24px 40px;
    display: flex;
    inset: 0%;
    overflow: auto;
  }

  .nav-brand {
    color: var(--black);
    height: 20px;
    margin-right: 0;
    font-size: 16px;
  }

  .brand-logo-wrapper {
    z-index: 999;
    position: relative;
  }

  .nav-location {
    font-size: 10px;
  }

  .menu-button {
    justify-content: center;
    align-items: center;
    padding: 0 6px;
    display: flex;
  }

  .w-nav .w-nav-menu {
    display: none;
  }

  .w-nav .w-nav-button {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .container_nav.nav {
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .main-grid {
    grid-column-gap: 16px;
  }

  .nav-brand {
    padding-left: 0;
  }

  .menu-nav {
    padding-left: 20px;
    padding-right: 20px;
  }

  .w-nav .w-nav-menu {
    display: none;
  }

  .w-nav[data-collapse="small"] .w-nav-button {
    display: block;
  }
}

@media screen and (max-width: 479px) {
  .w-nav .w-nav-menu {
    display: none;
  }

  .w-nav .w-nav-button {
    display: block;
  }

  .container_nav.nav {
    width: 100%;
  }

  .container_nav {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .main-grid {
    grid-template-columns: 1fr;
  }
}

.image_wrap {
  object-fit: fill;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.cover.small {
  -webkit-text-fill-color: inherit;
  object-fit: cover;
  height: 30vw;
}

.cover {
  object-fit: fill;
  width: 100%;
  max-width: none;
  margin-bottom: 0;
  font-size: 1px;
  display: block;
  overflow: visible;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-y {
  scroll-snap-type: y var(--tw-scroll-snap-strictness);
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.relative {
  position: relative;
}

.flex-auto {
  flex: auto;
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.p-4 {
  padding: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-32 {
  margin-top: 8rem;
}

.justify-center {
  justify-content: center;
}

.containerJ {
  width: 100%;
}

.self-start {
  align-self: flex-start;
}

@media (min-width: 640px) {
  .containerJ {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .containerJ {
    max-width: 768px;
  }

  .self-end {
    align-self: flex-end;
  }
}

@media (min-width: 1024px) {
  .containerJ {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .containerJ {
    max-width: 1280px;
  }

  .my-10a {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 1536px) {
  .containerJ {
    max-width: 1536px;
  }
}

@media (min-width: 768px) {
  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .flex-auto, .md\:flex-1 {
    flex: 1;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.h1 {
  font-weight: 600;
  line-height: 1;
  font-size: 5.5rem !important;
}

.hidden-mobile {
  display: block;
}

.flex {
  display: flex;
}

@media (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}

.hidden {
  display: none;
}

.w-24 {
  width: 6rem;
}

.h-24 {
  height: 6rem;
}

.text-circle__element {
  color: currentColor;
  text-align: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  font-size: 10px;
  animation: 20s linear infinite spinZ;
}

.text-circle__element span {
  text-transform: uppercase;
  font-size: 9px;
  transition: all .5s cubic-bezier(0, 0, 0, 1);
  display: inline-block;
  position: absolute;
  inset: 0;
}

.ml-auto {
  margin-left: auto;
}

.max-w-xl {
  max-width: 36rem;
}

@keyframes spinZ {
  to {
    transform: rotate(1turn);
  }
}

@media only screen and (min-width: 1366px) {
  .banner .containerAW {
    min-height: 809px;
  }

  .heading-main {
    margin-bottom: 45px;
  }
}

.banner .containerAW {
  min-height: 641px;
  position: relative;
}

.containerAW {
  width: 100%;
  max-width: 1280px;
}

@media only screen and (min-width: 375px) {
  .body__titleDI {
    font-size: 6.5rem;
    line-height: 1;
  }
}

.animate {
  animation: 1s forwards move;
}

.heading-largeRG {
  letter-spacing: -.08em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4rem;
  font-weight: 300;
  line-height: .85;
}

.flex-col {
  flex-direction: column;
}

.gap-y-4 {
  row-gap: 1rem;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.pb-\[175px\] {
  padding-bottom: 175px;
}

.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gap-16 {
  gap: 4rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.image-sec {
  max-width: 100%;
  height: auto;
}

.pb-8 {
  padding-bottom: 2rem;
}

.max-w-\[560px\] {
  max-width: 560px;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.aaa {
  width: 100%;
}

.nav-title-wrap .hid, .nav-title-wrap .main {
  transition: all .5s cubic-bezier(.65, .05, .36, 1);
}

.p-normal {
  letter-spacing: 0;
  line-height: 1.2em;
}

.nav-title-wrap .hid {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-120%)scaleY(1.5);
}

.nav-title-wrap {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.nav-title-wrap:hover .hid {
  transform: translateY(0)scaleY(1);
}

.nav-title-wrap:hover .main {
  transform: translateY(120%)scaleY(1.5);
}

.p-normal {
  display: inline-block;
}

@media (max-width: 960px) {
  .small-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.align-center {
  justify-content: center;
}

.image-array.scale {
  margin-bottom: 10.5263vw;
}

.Button_container {
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  text-decoration: none;
  display: flex;
}

.Button_mask {
  border-radius: 999px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px 12px;
  display: flex;
}

.Button_top {
  background-color: var(--black-rg);
  color: #f0f0f0;
}

@media screen and (max-width: 480px) {
  .Button_mask {
    padding: 8px 16px;
    position: relative;
  }
}

@media screen and (min-width: 1921px) {
  .Button_mask_ {
    padding: 8px 16px;
  }
}

.items-start {
  align-items: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.mbb-10 {
  margin-bottom: 1rem;
}

.mrr-10 {
  margin-right: 1rem;
}

.btnOC--small {
  padding: .5rem .8rem;
}

.btnOC--defaultdark {
  color: #fff;
  background: none;
  box-shadow: inset 0 0 0 1px #fff;
}

.btnOC--defaultdark:hover {
  color: #212121;
  box-shadow: inset 0 0 0 1px #fff;
}

.btnOC--defaultdark:after {
  background: #fff;
}

.btnOC--default {
  color: #212121;
  background: none;
  box-shadow: inset 0 0 0 1px #212121;
}

.btnOC {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  will-change: transform;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  line-height: 1;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.btnOC > * {
  z-index: 2;
  position: relative;
}

.btnOC__icon, .btn__text {
  z-index: 3;
  position: relative;
}

.btnOC--default:after {
  background: #212121;
}

.btnOC:after {
  content: "";
  transform-origin: top;
  will-change: transform;
  border-radius: 50%;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  position: absolute;
  inset: -100% -3rem;
  transform: scaleY(0);
}

.btnOC--default:hover {
  color: #fff;
  box-shadow: inset 0 0 0 1px #212121;
}

.btnOC:hover:after {
  transform-origin: bottom;
  transform: scaleY(1);
}

.Form_input__653_E {
  width: 100%;
  height: auto;
  padding-top: 24px;
  padding-bottom: 8px;
}

.Form_inputs__NaeI4 {
  opacity: 0;
  width: 0;
  height: 0;
}

.Form_label__HqQ1f {
  pointer-events: none;
  transform-origin: 0 0;
  margin-bottom: 0;
  margin-right: 0;
  font-weight: 400;
  transition: all .15s;
  position: absolute;
  top: 24px;
}

.Form_lineWrap__tN7k3 {
  background-color: #d6d6d6;
  width: 100%;
  height: 1px;
  margin-top: -1px;
  position: relative;
  overflow: hidden;
}

.Form_line__EwAjJ {
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
}

input, textarea {
  box-sizing: border-box;
  text-align: start;
  -webkit-appearance: auto;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  background-color: field;
  border-style: inset;
  border-width: 2px;
  border-color: -internal-light-dark(#767676, #858585);
  border-image: initial;
  padding: 1px 0;
  border-radius: 0;
  outline: none;
  margin: 0;
  padding-block: 1px;
  padding-inline: 2px;
  display: block;
}

textarea {
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  display: block;
}

input::placeholder, .Form_formGrid__cFE_P textarea::placeholder {
  color: #0000;
}

.Form_input__653_E:focus + .Form_label__HqQ1f {
  color: #949494;
  transform: translateY(-100%)scale(.75);
}

.Form_formGrid__cFE_P .Form_message__URb70:not(.Form_inputs__NaeI4) {
  grid-column: 1 / span 2;
}

.Form_field__5UEwb {
  height: 100%;
  position: relative;
}

.Form_formGrid__cFE_P {
  grid-column-gap: 2vw;
  grid-row-gap: 2vw;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media screen and (max-width: 480px) {
  .Form_formGrid__cFE_P {
    grid-template-columns: 1fr;
  }
}

input:focus, textarea:focus {
  outline: 0;
}

.cookies-hold {
  z-index: 300;
  padding: 8px;
  background: #fff;
  border-radius: 30px;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 48px);
  max-width: 410px;
  padding-left: 34px;
  display: flex;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 24px #00000014;
}

.cookies-text {
  color: #000000b3;
  letter-spacing: .01em;
  padding-right: 50px;
  font-size: 17px;
  line-height: 1.65;
}

.cookies-text a {
  color: #000;
  font-weight: 700;
}

.btn-black, .btn-black:focus, .btn-black:active {
  color: #fff;
  letter-spacing: .04em;
  text-align: center;
  cursor: pointer;
  background: #000;
  border-radius: 21px;
  justify-content: center;
  align-items: center;
  padding: 7px 24px;
  font-size: 14px;
  line-height: 2;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.btn-black span {
  white-space: nowrap;
  transition: transform .6s cubic-bezier(.25, .74, .22, .99);
}

.cookies-text a:hover {
  color: #000;
  text-decoration: underline;
}

.btn-black:before {
  content: "";
  z-index: 10;
  border-top: 10px solid #000;
  border-bottom: 10px solid #000;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.btn-black:after {
  content: attr(data-btn);
  white-space: nowrap;
  transition: transform .6s cubic-bezier(.25, .74, .22, .99);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(50px - 50%));
}

.btn-black:hover span {
  transform: translateY(-50px);
}

.btn-black:hover:after {
  transform: translate(-50%, -50%);
}

.btn-black:hover {
  color: #fff;
  background: #000;
}

.swipe {
  height: 100%;
  position: relative;
}

.swipe .swiper {
  width: 100%;
}

.swipe .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 500px;
  height: 500px;
}

.swipe .swiper-slide img, .swipe .swiper-slide picture {
  width: 100%;
  display: block;
}

.gallery-work img, .swipe .swiper-slide picture {
  object-fit: cover;
  object-position: 50% 40%;
  border-radius: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.swipe .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, black);
}

@media (min-width: 768px) {
  .swipe .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.swipe .py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.swipe .mb-8 {
  margin-bottom: 2rem;
}

.swipe .mb-16 {
  margin-bottom: 4rem;
}

.black-bg_color {
  background-color: var(--black);
  text-align: center;
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

hr {
  box-sizing: border-box;
  color: inherit;
  border: 0 solid #e5e7eb;
  border-top-width: 1px;
  height: 0;
}

.container-fluid {
  --bs-gutter-x: ;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.pyCn-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.form-container {
  flex-wrap: wrap;
  display: flex;
}

@media screen and (max-width: 767px) {
  .form-container {
    display: block;
  }
}

.form-container__heading {
  flex: 0 0 100%;
  margin: 0 0 100px;
}

@media screen and (max-width: 1659px) {
  .form-container__heading {
    margin: 0 0 80px;
  }
}

@media screen and (max-width: 1199px) {
  .form-container__heading {
    margin: 0 0 60px;
  }
}

@media screen and (max-width: 767px) {
  .form-container__heading {
    margin: 0 0 50px;
  }

  .form-container__heading h1 {
    max-width: 100%;
  }
}

.form-container__heading h1 {
  max-width: 70%;
}

.h1-c {
  font-size: 5rem;
  line-height: 1;
}

@media screen and (max-width: 1659px) {
  .form-container__heading h1 {
    max-width: 50%;
  }

  .h1-c {
    font-size: calc(1.575rem + 3.9vw);
    line-height: 1;
  }
}

@media screen and (max-width: 1199px) {
  .form-container__heading h1 {
    max-width: 75%;
  }

  .h1-c {
    font-size: calc(1.525rem + 3.3vw);
    line-height: 1;
  }
}

@media screen and (max-width: 767px) {
  .form-container__heading h1 {
    max-width: 100%;
  }
}

.form-container__left {
  flex: 0 0 45%;
}

.form-container__avatar {
  margin: 0 0 44px;
}

.form-container__avatar img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0;
}

.form-container__text {
  max-width: 60%;
}

@media screen and (max-width: 1199px) {
  .form-container__text {
    max-width: 65%;
  }
}

@media screen and (max-width: 1023px) {
  .form-container__text {
    max-width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .form-container__text {
    max-width: 100%;
    margin: 0 0 60px;
  }
}

.form-container__text > :first-child {
  margin-top: 0 !important;
}

.form-container__text > :last-child {
  margin-bottom: 0 !important;
}

.form-container__text p {
  font-size: 20px;
  line-height: 160%;
}

@media screen and (max-width: 1199px) {
  .form-container__text p {
    font-size: 18px;
  }
}

.form-container__text a {
  font-size: inherit;
  font-weight: 600;
  line-height: inherit;
  color: #000;
  text-decoration: underline;
}

.form-container__right {
  flex: 0 0 55%;
}

.form-container .wpcf7-response-output {
  max-width: 80%;
  margin: 0 0 60px !important;
  padding: 12px 20px !important;
}

@media screen and (max-width: 1659px) {
  .form-container .wpcf7-response-output {
    max-width: 85%;
  }
}

@media screen and (max-width: 1199px) {
  .form-container .wpcf7-response-output {
    max-width: unset;
  }
}

.contact-form {
  flex-direction: column;
  gap: 14px;
  max-width: 80%;
  display: flex;
}

@media screen and (max-width: 1659px) {
  .contact-form {
    max-width: 85%;
  }
}

@media screen and (max-width: 1199px) {
  .contact-form {
    max-width: unset;
    gap: 10px;
  }
}

.contact-form__input label {
  margin: 0 0 14px;
  font-size: 16px;
  line-height: 140%;
  display: block;
}

@media screen and (max-width: 1199px) {
  .contact-form__input label {
    margin: 0 0 12px;
  }
}

.contact-form__input input, .contact-form__input textarea {
  -webkit-appearance: none;
  border: 2px solid #00000040;
  width: 100%;
  height: 70px;
  padding: 0 20px;
  font-size: 20px;
  line-height: 1;
  box-shadow: none !important;
  outline: none !important;
}

@media screen and (max-width: 1659px) {
  .contact-form__input input, .contact-form__input textarea {
    height: 64px;
  }
}

@media screen and (max-width: 1199px) {
  .contact-form__input input, .contact-form__input textarea {
    height: 56px;
    font-size: 18px;
  }
}

.contact-form__input textarea {
  height: 200px;
  padding: 16px 20px;
  line-height: 140%;
}

@media screen and (max-width: 1659px) {
  .contact-form__input textarea {
    height: 175px;
  }
}

@media screen and (max-width: 1199px) {
  .contact-form__input textarea {
    height: 150px;
  }
}

.contact-form__input br {
  display: none;
}

.contact-form__input .wpcf7-not-valid-tip {
  margin: 6px 0 0;
  font-size: 14px;
  line-height: 140%;
}

.contact-form__submit {
  padding: 10px 0 0;
}

.contact-form__submit input {
  color: #fff;
  -webkit-appearance: none;
  background-color: #000;
  border-radius: 0;
  width: 100%;
  height: 70px;
  font-size: 20px;
  line-height: 1;
  box-shadow: none !important;
  border: 0 !important;
  outline: none !important;
}

@media screen and (max-width: 1659px) {
  .contact-form__submit input {
    height: 64px;
  }
}

@media screen and (max-width: 1199px) {
  .contact-form__submit input {
    height: 56px;
    font-size: 18px;
  }
}

.send {
  color: #f5f5f5;
  text-align: center;
  --box-shadow2: 0 1.4rem 8rem #0003;
  cursor: pointer;
  background: #131417;
  border: none;
  border-radius: 2px;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding: .5em 1em .5em .9em;
  font-family: inherit;
  font-size: 1.1rem;
  transition: all .2s;
  display: flex;
  overflow: hidden;
}

.send:hover svg {
  transform: translate(1.2em)rotate(45deg)scale(1.1);
}

.send svg {
  transform-origin: 50%;
  transition: transform .3s ease-in-out;
  display: block;
}

.send:hover .svg-wrapper {
  animation: .6s ease-in-out infinite alternate fly-1;
}

@keyframes fly-1 {
  0% {
    transform: translateY(.1em);
  }

  100% {
    transform: translateY(-.1em);
  }
}

.svg-wrapper, .svg-wrapper-1 {
  display: block;
}

.send:hover .text {
  transform: translate(19em);
}

.send .text {
  margin: 2px;
  transition: all .3s ease-in-out;
  display: block;
}

.breadcrumbs {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  font-size: 1.2rem;
  display: block;
}

.list_bread {
  vertical-align: middle;
  width: 100%;
  display: inline-block;
}

.breadcrumbs ol {
  white-space: normal;
  font-size: 1rem;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.breadcrumbs li:before {
  content: " ";
  -webkit-text-decoration: inherit;
  -webkit-text-decoration: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  padding: 0 7px;
  font-family: FontAwesome;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}

.gallery .swiper {
  width: 100%;
  height: 100%;
}

.gallery .swiper-slide {
  text-align: center;
  background: #fff;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  display: flex;
}

.gallery .swiper-slide img {
  object-fit: cover;
  display: block;
}

.gallery .swiper {
  cursor: grab;
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.gallery .swiper-slide {
  background-position: center;
  background-size: cover;
}

.gallery .mySwiper2 {
  width: 100%;
  height: 80%;
}

.gallery .mySwiper {
  box-sizing: border-box;
  height: 10%;
  padding: 10px 0;
}

.gallery .mySwiper .swiper-slide {
  opacity: .4;
  width: 25%;
  height: 100%;
}

.gallery .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.gallery .swiper-button-next, .gallery .swiper-button-prev {
  --swiper-navigation-size: 34px;
  --swiper-navigation-color: #828283;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  color: var(--swiper-navigation-color);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

@media screen and (min-width: 1024px) {
  #swiper7 .swiper-button-next, #swiper7 .swiper-button-prev {
    display: none;
  }
}

.gallery .justify-center {
  justify-content: center;
}

.gridem {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  position: relative;
}

.lineh-no {
  line-height: 1;
}

/*# sourceMappingURL=index.e20dc747.css.map */
